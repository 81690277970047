import {NavigationType, useNavigate} from '@remix-run/react';
import {Text} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {Modal} from '~/components';
import {type TransitionState} from '~/types';
import {createI18nConfig} from '~/utils/createI18nConfig';

import translations from './translations';

interface ConfirmLeaveModalProps {
  onHide(): void;
  transition?: TransitionState;
}

export function ConfirmLeaveModal({
  onHide,
  transition,
}: ConfirmLeaveModalProps) {
  const [i18n] = useI18n(createI18nConfig(translations));
  const navigate = useNavigate();
  return (
    <Modal
      open={Boolean(transition)}
      onHide={onHide}
      title={i18n.translate('ConfirmLeaveModal.title')}
      primaryAction={{
        content: i18n.translate('ConfirmLeaveModal.leave'),
        destructive: true,
        onAction: () => {
          if (!transition) {
            return;
          }

          const options = {
            state: {
              ...(typeof transition.location.state === 'object'
                ? transition.location.state
                : {}),
              skipConfirmation: true,
            },
          };

          switch (transition.action) {
            case NavigationType.Push: {
              navigate(transition.location, options);
              break;
            }
            case NavigationType.Replace:
            case NavigationType.Pop: {
              navigate(transition.location, {...options, replace: true});
              break;
            }
          }

          onHide();
        },
      }}
      secondaryActions={[
        {
          content: i18n.translate('ConfirmLeaveModal.cancel'),
          onAction: onHide,
        },
      ]}
    >
      <Modal.Section>
        <Text as="p" variant="bodyMd">
          {i18n.translate('ConfirmLeaveModal.message')}
        </Text>
      </Modal.Section>
    </Modal>
  );
}
