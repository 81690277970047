import React, {createContext, useContext, useMemo, useState} from 'react';

import {type TransitionState} from '~/types';

import {ConfirmLeaveModal} from './components';

const ConfirmLeaveModalContext = createContext<{
  confirmLeaveModalTransitionState: readonly [
    TransitionState | undefined,
    React.Dispatch<React.SetStateAction<TransitionState | undefined>>,
  ];
}>({
  confirmLeaveModalTransitionState: [undefined, () => {}],
});
interface ProviderProps {
  children: React.ReactNode;
}

export function ConfirmLeaveModalContextProvider({children}: ProviderProps) {
  const [confirmLeaveModalTransition, setConfirmLeaveModalTransition] =
    useState<TransitionState>();
  const value = useMemo(
    () => ({
      confirmLeaveModalTransitionState: [
        confirmLeaveModalTransition,
        setConfirmLeaveModalTransition,
      ] as const,
    }),
    [confirmLeaveModalTransition],
  );

  return (
    <ConfirmLeaveModalContext.Provider value={value}>
      <ConfirmLeaveModal
        transition={confirmLeaveModalTransition}
        onHide={() => {
          setConfirmLeaveModalTransition(undefined);
        }}
      />
      {children}
    </ConfirmLeaveModalContext.Provider>
  );
}

export function useConfirmLeaveModalContext() {
  return useContext(ConfirmLeaveModalContext);
}
